(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/admin/react-dialog-wrapper/assets/javascripts/helper.js') >= 0) return;  svs.modules.push('/components/admin/react-dialog-wrapper/assets/javascripts/helper.js');
"use strict";


const {
  dialog
} = svs.ui;

const button = _ref => {
  let {
    title,
    callback
  } = _ref;
  return {
    title,
    callback: callback ? callback : () => {}
  };
};
const injectToButtonCallbacks = (buttons, closeDialog) => {
  buttons.forEach(button => {
    const theCallback = button.callback;
    button.callback = (dialog, form) => {
      closeDialog();
      theCallback(dialog, form);
    };
  });
  return buttons;
};

const checkbox = _ref2 => {
  let {
    checked,
    text,
    value
  } = _ref2;
  return {
    checked: typeof checked === 'boolean' ? checked : false,
    text: text || '',
    type: dialog.message.CHECKBOX,
    value: typeof value === 'boolean' ? value : false
  };
};

const cmsType = {
  CONFIRM: 'confirm',
  ERROR: 'error',
  INFO: 'info',
  DEFAULT: 'default'
};

const icon = {
  [cmsType.CONFIRM]: 'checkmark',
  [cmsType.ERROR]: 'exclamation-sign',
  [cmsType.INFO]: 'information',
  [cmsType.DEFAULT]: 'information'
};

const newDialog = _ref3 => {
  let {
    area,
    autoClose,
    buttons,
    convertToPopup,
    icon,
    message,
    title,
    type
  } = _ref3;
  const theDialog = new dialog.Confirm({
    actions: injectToButtonCallbacks(buttons, () => theDialog.close()),
    area: area || dialog.area.POPUP,
    autoClose: autoClose || 0,
    branding: "cms dialog-branding-cms-type-".concat(type || 'default'),
    convertToPopup: convertToPopup || false,
    icon,
    message: message || [],
    title
  });
  dialog.api.add(theDialog);
  autoClose && setTimeout(() => theDialog.close(), autoClose);
  if (theDialog.el.parentNode) {
    const outmostElement = theDialog.el.parentNode.parentNode;
    outmostElement.classList.add('dialog-branding-cms-outmost');
    if (area) {
      theDialog.el.classList.add("dialog-branding-cms-".concat(area));
      outmostElement.classList.add("dialog-branding-cms-outmost-area-".concat(area));
    }
  }
  return theDialog;
};

const text = _ref4 => {
  let {
    text
  } = _ref4;
  return {
    text,
    type: dialog.message.TEXT
  };
};

const helper = {
  button,
  checkbox,
  cmsType,
  icon,
  newDialog,
  text
};
svs.components.dialogWrapper.helper = helper;

 })(window);